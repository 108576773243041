import React from "react"
import Layout from '../components/layout';

const TermsPage = () => {
	const seoData = {
		'title': 'Terms and Conditions page',
		'hide_month' : true,
		'description': 'Terms and Conditions page',
		'canonicalUrl': '/terms/',
		'robots': 'index,follow'
	};

	return (
		<Layout page={seoData} >
			<div className="about-us terms">
				<div className="abt-banner">
					<h1 className="container">User Agreement</h1>
				</div>
				<div className="container">
					<p> The Terms and Conditions (T&C) contained herein along with the <a href="#privacy-policy">Privacy Policy</a> and Terms of Use, form an Agreement regulating our relationship with regard to the use of HappyCredit (CYBERGENES INNOVATIONS PRIVATE LIMITED) 'https://happycredit.in' Platform (Site) by you.</p>

					<p>These terms of use <b>(Terms)</b> constitute a legally binding agreement between you and the Company regarding your use of the web site i.e. https://happycredit.in (the Site) and any services offered by the company including but not limited to delivery of content via the Site, any mobile or internet connected device or otherwise (the "the Service"). By accessing the Site or Service and/or by clicking "I agree", you agree to be bound by these Terms. You hereby represent and warrant to the Company that you are at least eighteen (18) years of age or above and are capable of entering, performing and adhering to these Terms and that you agree to be bound by the following terms and conditions. While individuals under the age of 18 may utilize the Service of the site, they shall do so only with the involvement & guidance of their parents and / or legal guardians, under such Parent /Legal guardian's registered account. You agree to register prior to uploading any content and / or comment and any other use or services of this site and provide your details including but not limited to complete name, age, email address, residential address, contact number. Please read this Agreement carefully. You are advised to regularly check for any amendments or updates to the terms and conditions from time to time. HappyCredit (CYBERGENES INNOVATIONS PRIVATE LIMITED) may add to or change or update these Terms of Use, from time to time entirely at its own discretion. You are responsible for checking these Terms of Use periodically to remain in compliance with these terms. Your use of Site and any amendment to the Terms of Use shall constitute your acceptance of these terms and you also agree to be bound by any such changes/revisions/amendments.</p>

					<p>You accept the Site Terms of Use, Privacy Policy and other Site policies by clicking the <b>"Signup/ Login/Register/Join"</b> button when registering and logging into the Site; accessing, visting or using Site, services, applications, and tools provided herein; or as otherwise indicated on a specific site, service, application, or tool from time to time. Some Site services, applications, and tools may have additional or other terms, agreements, or policies that govern their availability and use. Your use of and access to such sites, services, applications, and tools are subject to any and all terms, agreements, or policies applicable to them.</p>

					<p>Any clause of terms and conditions if deemed invalid, void or for any reason unenforceable, shall be deemed severable and shall not affect the validity and enforceability of the remaining clauses of the terms and conditions.</p>
					<h4>Definitions</h4>
					<p><b>"Agreement"</b> means the Terms and Conditions (T&C) contained herein along with the Privacy Policy and Terms of Use including other T&C at other portals of HappyCredit (CYBERGENES INNOVATIONS PRIVATE LIMITED) under the name. It will also include references to this Agreement as and when amended, notated, supplemented, varied or replaced.</p>
					<p><b>"WebSite/ Site"</b> or "https://happycredit.in" includes all online platform(s) owned and/or operated by HappyCredit (CYBERGENES INNOVATIONS PRIVATE LIMITED) providing users with the facility to find and use coupons, deals, discounts, offers and rewards both on HappyCredit’s own platforms or external merchants and marketplaces. "Vendor" / "seller" / "affiliates" shall mean the person or any legal entity who offers for sale, sells, displays, the deals/ Coupons on the online platform provided by their respective website and delivers the ordered products to the customers.</p>
					<p><b>"Customer" / "Buyer"</b> shall mean the person or any legal entity who accepts the offer for sale on goods/services by placing an order for and or purchases any deal/ Coupons offered for sale on affiliates.</p>
					<p><b>"User/You/ Your"</b> means and includes you and/or any person or an entity including Vendor/Seller using or accessing the services provided on this Site and any person who access or avail this site of the Company for the purpose of hosting, publishing, sharing, transacting, displaying or uploading information or views and includes other persons jointly participating in using the Site.</p>
					<p>The term <b>"We", "Us", "Our"</b> shall mean the Company.</p>
					<p><b>"Product/s"</b> connotes the goods, services promoted / displayed on the Site and offered for any use / sale.</p>
					<h4>Online Purchases</h4>
					<p>This Website is only a venue where Users may meet and interact with one another for their sale and purchase transactions. Therefore, HappyCredit acts as a facilitator for such sale and all commercial / contractual terms are offered by and agreed to between buyers and sellers alone. The commercial / contractual terms include without limitation price, shipping costs, date, period, mode of delivery, warranties related to products and services including after sales services related to products and services, etc., over which the seller has complete control and the same may be subject to change.</p>
					<p>You agree, understand and acknowledge that https://happycredit.in is an online platform, a venue enabling users to buy any highly curated list of deals, coupons and extra discounts by means of cash back. Once a User chooses the most appropriate deal through the Website, they are automatically redirected to the concerned Merchant’s website where the User can purchase the products on which the offer is applicable. </p>
					<p>HappyCredit reserves the right to modify/change all or any of the terms of applicable to Cashback(s) offer(s) without assigning any reason or without any intimation whatsoever to User. Further, HappyCredit also reserves the right to discontinue the Cashback(s) offer without assigning any reason or without any prior intimation whatsoever.</p>
					<p>You further agree and acknowledge that the Site is not involved in any sale and purchase of goods/products, and cannot warranty the quality and merchantability of the goods purchased utilising the deals/ coupons purchased. HappyCredit is only a facilitator, an intermediary and is not and cannot be a party to or control in any manner any transactions between the Vendor/Seller and the Customer/Buyer. HappyCredit shall neither be responsible nor liable to mediate or resolve any disputes or disagreements between the Users and the Vendors.</p>
					<p>HappyCredit shall neither be liable nor responsible for any actions or inactions of Vendors and/or Sellers or any breach of conditions, representations or warranties by the Vendor or manufacturer of the products. HappyCredit does not sell or retail any products and does not ensure that the Users shall perform their obligations in respect of the transaction concluded on this Site and further expressly disclaims any warranties or representations express or implied in respect of quality, safety, merchantability, fitness for a particular purpose, or legality of the products listed and transacted on Website.</p>
					<p>You further agree and undertake that you are accessing the services available on this Site and transacting at your sole risk and are using your best and prudent judgment before entering into any transaction through this Site.</p>
					<p>HappyCredit accepts no liability for any errors or omissions, whether on behalf of itself or third parties.</p>
					<p>HappyCredit shall not be responsible and shall not be responsible for any non-performance or breach of any contract entered into between Users.</p>
					<p>Before placing an order you are advised to check the Deal description carefully. By placing an order for a Deal you agree to be bound by the conditions of sale included in the Deal’s description.</p>
					<p>The agreement between you and HappyCredit is subject to the following terms and conditions:</p>
					<ul>
						<li>The User certifies that he/she is at least 18 (eighteen) years of age.</li>
						<li>These terms and conditions supersede all previous representations, understandings, or agreements and shall prevail notwithstanding any variance with any other terms of any order submitted or your login through other group or related ID. By using the services of HappyCredit you agree to be bound by the Terms and Conditions.</li>
						<li>All prices, unless indicated otherwise are in Indian Rupees.</li>
						<li>By indicating User's acceptance to purchase any product or service offered on the site, user is obligated to complete such transactions after making payment. Users shall prohibit from indicating its acceptance to purchase products and services where it does not intend to complete such transactions.</li>
						<li>HappyCredit is neither responsible for any non-performance or breach of any contract entered into between Users nor make any representation or Warranty as to the attributes (such as quality, worth, marketability, etc.) of the products or services proposed to be sold or offered to be sold by the seller or purchased by the buyer, on the Website.</li>
						<li>You are advised to independently verify the bona fides of any particular Deal/ coupons that you choose to buy with on the Website and use your best judgment in that behalf. HappyCredit, accepts no liability for any errors or omissions, whether on behalf of itself or its channel partners/ Sellers or third parties.</li>
						<li>In a credit card transaction, you must use your own credit card. HappyCredit will not be liable for any credit card fraud. The liability to use a card fraudulently will be on the user and the onus to 'prove otherwise' shall be exclusively on the user. To provide a safe and secure shopping experience, we regularly monitor transactions for fraudulent activity. In the event of detecting any suspicious activity, HappyCredit reserves the right to cancel all past, pending and future orders without any liability.</li>
						<li>The User agrees to use the services provided by HappyCredit, its affiliates, consultants and contracted companies, for lawful purposes only.</li>
						<li>The User agrees not to make any bulk purchase to indulge into any reseller activities. In case of any such instances, HappyCredit reserves all rights to cancel the current and future orders and block the concerned User account.</li>
						<li>The User agrees to provide authentic and true information. HappyCredit reserves the right to confirm and validate the information and other details provided by the User at any point of time. If upon confirmation such User details are found to be false, not to be true (wholly or partly), HappyCredit shall in its sole discretion reject the registration and debar the User from using the Services available on its Website, and / or other affiliated websites without prior intimation whatsoever.</li>
						<li>HappyCredit will not be responsible for any damage suffered by users from use of the services on this site. This without limitation includes loss of revenue/data resulting from delays, non-deliveries, missed deliveries, or service interruptions as may occur because of any act / omission of parties. This disclaimer of liability also applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tortuous behavior, negligence, or under any other cause of action.</li>
						<li>Failure/ denial by a channel partner to honor the deal/ Coupon or coupon code not working in case of online coupons needs to be reported within 24 hrs of the purchase of the deal/ coupon.</li>
						<li>HappyCredit as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</li>
						<li>HappyCredit reserves the right to send transactional emails,SMS's, web and app push notifications and whatsapp notifications related to any order placed by a User and the User agrees that the same will not tantamount to spam. By submitting your mobile number, you agree to receive calls/SMS's/Whatsapp messages from HappyCredit or its authorized representative, irrespective of your registration on Do Not Disturb (DND) with your telecom service provider.</li>
						<li>You agree to receive calls from HappyCredit and its agents, merchants, courier partners seeking clarification/information regarding your order and any other related information as and when required and disclaim any liability of HappyCredit in this regard.</li>
					</ul>
					<h4>Third Party Information</h4>
					<p>The content on the Website which includes all text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, design documents, and artwork is a combination of Third Party content and Our content. We have no control over such third party user generated content as We are merely an intermediary for the purposes of this Terms of Service. In the event, if any of the Third Party Content infringes any Intellectual Property of any person, the User shall be solely responsible for any loss caused and We shall not be liable. You may send us an email at info@happycredit.in.in to report any such content.</p>
					<p>The use of such content and it being reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any other computer, server, Website or other medium for publication or distribution or for any commercial enterprise, without Our express prior written consent is not allowed.</p>
					<h4>Reviews, comments and other User Content</h4>
					<p>The content that You upload or post will become Our property and You grant Us the worldwide, perpetual and transferable rights in such Content. We shall be entitled to, consistent with Our Privacy Policy as adopted in accordance with applicable law, use the Content, or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media whether now known or hereafter devised, including the creation of derivative works that may include the Content You provide and are not entitled to any payment or other compensation for such use. You also grant Us the right to sub-license these rights, and the right to bring an action for infringement of these rights. We reserve the right to edit or remove any material submitted to this Website, or stored on Our servers, or hosted or published upon this Website.</p>
					<p>We have the right to upload content on behalf of third parties, subsequent to collecting such information and verifying it if we deem it necessary. Notwithstanding this, We can in no way be held liable for any false or misleading information.</p>
					<p>Users of our website may post reviews, comments and other content; as long as the content is not illegal, obscene, abusive, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties, or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam". In case a User uses a false e-mail address, impersonates any person or entity, or otherwise misleads as to the origin of any content, HappyCredit the right to remove, refuse, delete or edit any content that violates these Conditions of use and, or terminate your permission to access or use our website.</p>
					<h4>Typographical Errors</h4>
					<p>In the event a Deal/Coupon is listed at an incorrect price or with incorrect information due to typographical error or error in pricing or contents of Deal/ Coupon information received from our channel partners or suppliers, HappyCredit shall have the right to refuse or cancel any orders placed for deals/ Coupons listed with incorrect price or information. HappyCredit shall have the right to refuse or cancel any such orders whether or not the order has been confirmed and your credit/debit card charged.</p>
					<h4>Credit card details</h4>
					<p>You agree, understand, undertake and confirm that the credit/debit card details provided by You for making payments for deals/ Coupons purchased through Website, either through the on-line payment gateway mechanism will be correct, current, complete and accurate and you shall not use the credit card/ debit card or any other bank account which is not lawfully owned by you. The aforesaid information provided by You will not be shared by HappyCredit with any of the third parties unless required by law, regulation or court order or for facilitating and completing the purchases made by You.</p>
					<p>HappyCredit will not be responsible for any financial loss, inconvenience or mental agony resulting from misuse of your ID/password/credit card number/account details in anyway.</p>
					<h4>Registration/ Membership</h4>
					<p>Membership cannot be availed of by those who are deemed Incompetent to Contract under the Indian Contract Act, 1872 subject to the aforementioned conditions.</p>
					<p>User may also choose to link your social network profile of Facebook and Google with Us and use that as the login credentials. HappyCredit shall use the same to retrieve certain details from them including name, birthday, gender, location, email ID and access Your friends’ list. However HappyCredit shall not post anything on User’s behalf without User’s permission.</p>
					<p>Registration of the User on the Website is Mandatory. The User agrees and undertakes at all times to be responsible for maintaining the confidentiality of the password and user id, and shall be fully responsible for all activities that occur by use of such password or user id. Further, the User agrees not to use any other party's under ID and Password for any purpose whatsoever without proper authorization from such party. User are responsible for the security of User's password and for all transactions undertaken using User's password through our service. User confirms that he/she is the authorized holder of the credit card or the original account holder used in the transactions under this Site. HappyCredit will not be responsible for any financial loss, inconvenience or mental agony resulting from misuse of user's ID/password/credit card number/account details number for using HappyCredit Services.</p>
					<p>The user also agrees and undertakes to immediately notify HappyCredit of any unauthorized use of the user's password or user ID and to ensure that the user logs off at the end of each session at the website. HappyCredit shall not be responsible for any, direct or indirect, loss or damage arising out of the user's failure to comply with this requirement.</p>
					<p>The membership of this Website is free of cost and this includes only the browsing of the Website. You hereby understand that this no charge policy may be amended at anytime in the discretion of the Website and the Company. The User also agrees to:</p>
					<ul>
						<li>provide true, accurate and complete information about himself and his beneficiaries as prompted by the registration form ("Registration Data") on the Website; and</li>
						<li>maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If the User provides any information that is untrue, inaccurate, not current or incomplete or HappyCredit has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, HappyCredit shall have a right to suspend or terminate User's registration and refuse any and all current or future use of the Website and/or any Service.</li>
						<li>Furthermore, the User grants HappyCredit the right to disclose to third parties Registration Data to the extent necessary for the purpose of carrying out the Services.</li>
					</ul>
					<h4>User Obligations</h4>
					<p>You are a restricted user of this Website.</p>
					<ul>
						<li>You are bound not to cut, copy, distribute, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or software obtained from the Website. With Our prior permission limited use may be allowed. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information within the content of the Website is not permitted.</li>
						<li>You agree not to access (or attempt to access) the Website and/or the materials or Services by any means other than through the interface that is provided by the website. The use of deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or Content, or in any way reproduce or circumvent the navigational structure or presentation of the Website, materials or any Content, to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Website. You acknowledge and agree that by accessing or using the Website or Services, You may be exposed to content from other users that You may consider offensive, indecent or otherwise objectionable. We disclaim all liabilities arising in relation to such offensive content on the Website. Further, You may report such offensive content by sending us an e-mail at info@happycredit.in</li>
						<li>You hereby agree not to access another User’s account or post as another User through an account not owned by You.</li>
						<li>In the event You are able to view another User’s account and confidential information, You are required to send an email to Us notifying Us of the same immediately on info@happycredit.in</li>
						<li>You hereby agree not to provide information about the functioning of the Website on any public forum.</li>
						<li>You may not transmit any chain letters or unsolicited commercial or junk email to other Users via the Website. It shall be a violation of these Terms of Service to use any information obtained from the Website in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than Us without Our prior explicit consent. While We shall not be held liable or answerable to Users for any such acts on Your part, violation of this clause would lead to potential criminal legal action against You, either by other Users of the Website or by Us.</li>
						<li>We can (and You hereby expressly authorize Us to) disclose any information about You to law enforcement or other government officials, as We, in Our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury. You understand that We have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Website) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena.</li>
					</ul>
					<h4>Prohibited Activities</h4>
					<p>You agree that you shall not host, display, upload, modify, publish, transmit, update or share any information on the Site, that</p>
					<ul>
						<li>belongs to another person and to which you do not have any right to;</li>
						<li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
						<li>harm minors in any way;</li>
						<li>infringes any patent, trademark, copyright or other proprietary rights;</li>
						<li>violates any law for the time being in force;</li>
						<li>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
						<li>impersonate another person;</li>
						<li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
						<li>Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;</li>
						<li>Engage in any activity that interferes with or disrupts access to the Website or the Services (or the servers and networks which are connected to the Website);</li>
						<li>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</li>
						<li>Publish, post, disseminate, any information which is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</li>
						<li>Directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</li>
						<li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation.</li>
					</ul>
					<p>Any Content and or comment uploaded by you, shall be subject to relevant Indian laws and may be disabled, or and may be subject to investigation under appropriate laws. Furthermore, if you are found to be in non-compliance with the laws and regulations, these terms, or the privacy policy of the Site, the Company shall have the right to immediately terminate/block your access and usage of the Site and the Company shall have the right to immediately remove any non-compliant Content and or comment, uploaded by you and shall further have the right to take recourse to such remedies as would be available to the Company under the applicable laws.</p>
					<h4>Indemnity</h4>
					<p>You shall indemnify and hold harmless to HappyCredit and (as applicable) HappyCredit’s parent, subsidiaries, affiliates, third-parties and their respective officers, directors, agents, and employees, from any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, caused by or arising out of claims based upon the use of User's actions or inactions, including but not limited to any warranties, representations or undertakings or in relation to the non-fulfilment of any of its obligations under this Agreement or arising out of the User's infringement of any applicable laws, regulations including but not limited to Intellectual Property Rights, Confidentiality obligations, payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers and infringement of intellectual property or other rights.</p>
					<p>This clause shall survive the expiry or termination of this Agreement.</p>
					<h4>Termination /Suspension</h4>
					<p>HappyCredit reserves its right to limit, refuse service, restrict, suspend, prohibit access to the Site, terminate your account and services; (Terminate this Agreement; Terminate or suspend your access to the Web Sites; Move, or remove any product or service that is available on or through the Services; Deactivate or delete your accounts and all related information and files in your account; Establish general practices and limits concerning use of this Site) at any time, in its sole discretion with or without cause, and with or without any prior notice for any violation of the Terms of Use. Upon such termination or suspension, your right to use the HappyCredit's Web Sites will immediately cease. Once the User's registration or the Services are terminated, cancelled or suspended, any data that the User has stored on the Website may not be retrieved later. The User agrees that HappyCredit may under certain circumstances and without prior notice, immediately terminate the User's user ID and access to the Website/Services. Causes for termination may include, but shall not be limited to requests by enforcement or government agencies, possible legal liabilities, infringing the intellectual property rights of any third party, anything acting inconsistently with the letter of spirit of our policies or harassing our employees etc.</p>
					<p>Disclaimer Of Warranties/Limitation Of Liability</p>
					<p>THE WEBSITE, SERVICES AND OTHER MATERIALS ARE PROVIDED BY THIS WEBSITE IS ON AN "AS IS" BASIS WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>
					<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE WILL HAVE NO LIABILITY RELATED TO USER CONTENT ARISING UNDER INTELLECTUAL PROPERTY RIGHTS, LIBEL, PRIVACY, PUBLICITY, OBSCENITY OR OTHER LAWS. THE WEBSITE ALSO DISCLAIMS ALL LIABILITY WITH RESPECT TO THE MISUSE, LOSS, MODIFICATION OR UNAVAILABILITY OF ANY USER CONTENT.</p>
					<p>USER ACKNOWLEDGES THAT THROUGH THIS SITE, HAPPYCREDIT MERELY ACTS AS A FACILITATOR, IN ORDER TO FACILITATE HIGHEST QUALITY SERVICES TO THE USERS. HAPPYCREDIT DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THIS SITE WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVERS THAT MAKE IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, BUT SHALL ENDEAVOUR TO ENSURE USER FULLEST SATISFACTION.</p>
					<p>HAPPYCREDIT IS NOT LIABLE FOR ANY CLAIMS AGAINST LACK OR DEFICIENCY OF SERVICES; NON-FULFILLMENT OR UNSATISFACTORY FULFILLMENT OF PRODUCTS AND SERVICES PURCHASED BY USER FROM THE THIRD PARTY. THUS, HAPPYCREDIT SHALL NOT HAVE ANY LIABILITY WHATSOEVER FOR ANY ASPECT OF THE ARRANGEMENTS BETWEEN THE USERS OF THE SITE AS REGARDS THE STANDARDS OF PRODUCTS AND SERVICES. IN NO EVENT SHALL HAPPYCREDIT BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY OTHER DAMAGES RESULTING FROM: (A) THE USE OR THE INABILITY TO USE THE PRODUCTS AND SERVICES; (B) THE COST OF PROCUREMENT OF SUBSTITUTE PRODUCTS AND SERVICES; (C) UNAUTHORIZED ACCESS TO OR ALTERATION OF THE USER'S TRANSMISSIONS OR DATA; (D) ANY OTHER MATTER RELATING TO THE SERVICES; INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE WEBSITE.</p>
					<p>HAPPYCREDIT SHALL NOT BE HELD RESPONSIBLE FOR NON-AVAILABILITY OF THE WEBSITE DURING PERIODIC MAINTENANCE OPERATIONS OR ANY UNPLANNED SUSPENSION OF ACCESS TO THE WEBSITE THAT MAY OCCUR DUE TO TECHNICAL REASONS OR FOR ANY REASON BEYOND HAPPYCREDIT'S CONTROL. THE USER UNDERSTANDS AND AGREES THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE WEBSITE IS DONE ENTIRELY AT THEIR OWN DISCRETION AND RISK AND THEY WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO THEIR COMPUTER SYSTEMS OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA.</p>
					<p>YOU FURTHER AGREE AND ACKNOWLEDGE THAT THAT WE ARE IN NO WAY LIABLE FOR THE FUNCTIONING AND NON FUNCTIONING OF THE OTHER SHOPPING WEBSITES WHICH YOU SHALL BE REDIRECTED TO UPON CHOOSING THE APPROPRIATE COUPON. WE SHALL NOT BE LIABLE FOR ANY DEAL OR COUPON WHICH HAS TERMINATED OR INVALIDATED BY THE THIRD PARTY SHOPPING SITES. WE SHALL NOT BE LIABLE FOR THE PRODUCTS SOLD BY THE THIRD PARTY SHOPPING SITES AND THE SERVICES RENDERED BY THEM. FOR ANY ISSUES WITH REGARD TO YOUR PURCHASE, THE THIRD PARTY SHOPPING SITES SHALL BE RESPONSIBLE AND WE SHALL HAVE NO INVOLVEMENT IN THE MATTER. FOR ALL ISSUES REGARDING SALES, PRODUCTS, SERVICES AND OTHER MATTERS, YOU SHALL BE GOVERNED BY THE SHOPPING WEBSITE’S TERMS OF SERVICE AND PRIVACY POLICY AND WE SHALL HAVE NO ASSOCIATION WITH THE SAME. WE STRONGLY ADVISE YOU TO READ THROUGH THEIR TERMS OF SERVICE AND PRIVACY POLICY PRIOR TO ANY PURCHASE.</p>
					<p>YOU ALSO UNDERSTAND WE ARE UNDER NO LIABILITY TO PROVIDE A CASH – BACK FOR ANY DEAL YOU UTILIZE FROM OUR WEBSITE. CASH BACK IS A REWARD SCHEME WHICH IS SUBJECT TO VARIOUS TECHNOLOGICAL AND COMMERCIAL CONDITIONS. IN THE EVENT, THE CASHBACK IS NOT TRACKED BACK TO YOU OR IN THE EVENT, WE DO NOT RECEIVE ANY COMMISSION FOR A PARTICULAR DEAL, YOU UNDERSTAND THAT WE SHALL NOT BE LIABLE FOR ANY NON PAYMENT OF CASH-BACK.</p>
					<p>YOU MAY REDEEM THE CASHBACK ONLY IN ACCORDANCE WITH THE WITHDRAWAL RULES PRESCRIBED BY US FROM TIME TO TIME.</p>
					<p>THIS CLAUSE SHALL SURVIVE THE TERMINATION OR EXPIRY OF THIS AGREEMENT.</p>
					<h4>Relationship</h4>
					<p>None of the provisions of this Agreement, terms and conditions, notices or the right to use the Website by the User contained herein or any other section or pages of the Website and/or the Linked Sites, shall be deemed to constitute a partnership between the User and HappyCredit and no party shall have any authority to bind or shall be deemed to be the agent of the other in any way. It may be noted, however, that if by using the Website, the User authorizes HappyCredit and its agents to access third party sites designated by them or on their behalf for retrieving requested information, the User shall be deemed to have appointed HappyCredit and its agents as their agent for this purpose.</p>
					<h4>Entire Agreement</h4>
					<p>The Terms and Conditions, together with any terms and conditions incorporated herein or referred to herein constitute the entire agreement between us relating to the subject matter hereof, and supersedes any prior understandings or agreements (whether oral or written) regarding the subject matter, and may not be amended or modified except in writing or by making such amendments or modifications available on this site.</p>
					<h4>Headings</h4>
					<p>The headings and sub-headings herein are included for convenience and identification only and are not intended to describe, interpret, define or limit the scope, extent or intent of this Service or the right to use the Website by the User contained herein or any other section or pages of the Website or any Linked Sites in any manner whatsoever.</p>
					<h4>Communication with Users</h4>
					<p>HappyCredit reserves the right to communicate with Users regarding this site and User's use of this site or any product or service purchased by User on this site.</p>
					<h4>Force Majeure</h4>
					<p>HappyCredit shall have no liability to you for any interruption or delay, to access the Site irrespective of the cause.</p>
					<h4>No Waiver</h4>
					<p>Our failure to insist upon or enforce your strict compliance with the Agreement will not constitute a waiver of any of our rights.</p>
					<h4>Severability</h4>
					<p>If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>
					<h4>Governing Law</h4>
					<p>This agreement and each TOS shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the courts of Bangalore.</p>
					<h4>Miscellaneous</h4>
					<p>We reserve the right to change these Terms of Service at any time in its discretion and to notify users of any such changes solely by changing this Terms of Service. Your continued use of the Website after the posting of any amended Terms of Service shall constitute your agreement to be bound by any such changes. Your use of this Website prior to the time this Terms of Service was posted will be governed according to the Terms of Service that applied at the time of your use.</p>
					<p>We may modify, suspend, discontinue or restrict the use of any portion, including the availability of any portion of the Content at any time, without notice or liability. We may deny access to any person or user at any time for any reason. In addition, we may at any time transfer rights and obligations under this Agreement to any affiliate, subsidiary or business unit, or any of their affiliated companies or divisions, or any entity that acquires the Company or any of their assets.</p>
					<h4>Notice of Copyright Infringement</h4>
					<p>HappyCredit is not liable for any infringement of copyright arising out of materials posted on or transmitted through the site, or items advertised on the site, by end users or any other third parties. Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to Happy Credit as mentioned below via in writing or through email signed with the electronic signature addressed to info@happycredit.in or write at the following address:</p>
					<p><b>
					HappyCredit,<br />
					(Cybergenes Innovations Pvt Ltd),<br />
					#677, 1st Floor, Suite 973, 13th Cross Road,<br />
					27th Main Road, Sector - 1, HSR Layout,<br />
					Bengaluru, Karnataka - 560102, India.<br />
					Phone: +91-95134 26304<br />
					Email: info@happycredit.in	
					</b></p>
					<p>Request you to please provide the following information in your complaint:-</p>
					<ul>
						<li>A physical or electronic signature of a person authorized to act on behalf of the copyright owner for the purposes of the complaint.</li>
						<li>Identification of the copyrighted work claimed to have been infringed.</li>
						<li>Identification of the material on our website that is claimed to be infringing or to be the subject of infringing activity.</li>
						<li>The address, telephone number or e-mail address of the complaining party.</li>
						<li>A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law.</li>
						<li>A statement, under penalty of perjury, that the information in the notice of copyright infringement is accurate, and that the complaining party is authorized to act on behalf of the owner of the right that is allegedly infringed.</li>
					</ul>
				</div>
			</div>
			<div className="about-us terms" id="privacy-policy">
			<div className="abt-banner">
			<h1 className="container">Privacy Policy</h1>
			</div>
			<div className="container">
			<p>CYBERGENES INNOVATIONS PRIVATE LIMITED, (“HappyCredit”) owns, powers/ manages the website https://happycredit.in. HappyCredit/we respect the privacy of it's users and is committed to protect it in all respects. With a view to offer most enriching and holistic internet experience to its users, HappyCredit offers a vast repository of Online Sites and variety of community services. The information about the user as collected by HappyCredit is: (a) information supplied by users and (b) information automatically tracked while navigation (Information).</p>
			<p>By using HappyCredit's website or its services, you consent to the collection, storage, and use of the personal information you provide (including any changes thereto as provided by you) for any of the services that we offer.</p>
			<p>Your continued use of this app means that HappyCredit has your consent to send periodic communication regarding use of this site or any product or service purchased (by user) on this app. This communication can be through different media at HappyCredit's disposal (e.g. e-mails, promotional SMS, Push Notifications etc.) HappyCredit reserves the right to communicate with users regarding the use of the site or any product or service purchased on this site.</p>
			<h4>USER INFORMATION</h4>
			<p>To avail certain sites / services on our websites, users are required to provide certain information for the registration process that may include but not limited to :- a) your name, b) email address, c) sex, d) age, e) PIN code , f) credit card or debit card details g) medical records and history h) sexual orientation, i) biometric information, j) password etc., and / or your occupation, interests, and the like. The Information as supplied by the users enables us to improve our sites and provide you the most user-friendly experience.</p>
			<p>All required information is service dependent and HappyCredit may use the above said user information to, maintain, protect, and improve its services (including advertising services) and for developing new services.</p>
			<p>Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made there under or any other law for the time being in force.</p>
			<p>For placing an order on or through https://happycredit.in the information shall be used to service user requests, and the information shall include the user's name, mailing address, email and phone number. This information shall be gathered on the purchase of products/gift certificates, or sign up for email notifications. We also store all the user data in a secure and encrypted manner. </p>
			<p>The primary reason for gathering information is:-</p>
			<ul>
				<li>Provide you with the Service(s).</li>
				<li>Assess the needs of your business to determine or suggest suitable products.</li>
				<li>Send you requested product or service information.</li>
				<li>Respond to customer service requests.</li>
				<li>Send you promotional and marketing communications.</li>
				<li>Personalised Advertisement</li>
				<li>Perform statistical analyses of user behaviour and characteristics at an aggregate level in order to measure interest in and use of the various areas of the site.</li>
			</ul>
			<p>When you provide us with personal information about your contacts we will only use this information for the specific reason for which it is provided.</p>
			<h4>COOKIES</h4>
			<p>To improve the responsiveness for our users, we may use "cookies", or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the user's individual interests using the identified computer. Unless you voluntarily identify yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a cookie to your computer. The only personal information a cookie can contain is information you supply. A cookie cannot read data off your hard drive. Our advertisers may also assign their own cookies to your browser (if you click on their ads), a process that we do not control. We receive and store certain types of information whenever you interact with us via website, application or Service through your computer/laptop/netbook or mobile/tablet/pad/handheld device etc.</p>
			<h4>Log File Information</h4>
			<p>We automatically collect limited information about your computer's connection to the Internet, mobile number, including your IP address, when you visit our site, application or service. Your IP address is a number that lets computers attached to the Internet know where to send you data -- such as the pages you view. We automatically receive and log information from your browser, including your IP address, your computer's name, your operating system, browser type and version, CPU speed, and connection speed. We may also collect log information from your device, including your location, IP address, your device's name, device's serial number or unique identification number (e.g. UDiD on your iOS device), your device operating system, browser type and version, CPU speed, and connection speed etc.</p>
			<h4>Information from other Sources:</h4>
			<p>We may receive information about you from other sources, add it to our account information and treat it in accordance with this policy. If you provide information to the platform provider or other partner, whom we provide services, your account information and order information may be passed on to us. We may obtain updated contact information from third parties in order to correct our records and fulfil the Services or to communicate with you.</p>
			<h4>Demographic and purchase information:</h4>
			<p>We may reference other sources of demographic and other information in order to provide you with more targeted communications and promotions. We use Google Analytics, among others, to track the user behaviour on our website. Google Analytics specifically has been enabled to support display advertising towards helping us gain an understanding of our users' Demographics and Interests. The reports are anonymous and cannot be associated with any individual personally identifiable information that you may have shared with us. If you don’t want Analytics to be used in your browser, you can install the Google Analytics browser add-on</p>
			<h4>LINKS TO THIRD PARTY SITES / AD-SERVERS</h4>
			<p>The website or application may include links to other websites or applications. Such websites or applications are governed by their respective privacy policies, which are beyond our control. Once you leave our servers (you can tell where you are by checking the URL in the location bar on your browser), use of any information you provide is governed by the privacy policy of the operator of the application, you are visiting. That policy may differ from ours. If you can't find the privacy policy of any of these sites via a link from the application's homepage, you should contact the application or website owners directly for more information. When we present information to our advertisers -- to help them understand our audience and confirm the value of advertising on our websites or Applications -- it is usually in the form of aggregated statistics on traffic to various pages / content within our websites or Applications. We use third-party advertising companies to serve ads when you visit our websites or Applications. These companies may use information (not including your name, address, email address or telephone number or other personally identifiable information) about your visits to this and other websites or application, in order to provide advertisements about goods and services of interest to you. We do not provide any personally identifiable information to third party websites / advertisers / ad-servers without your consent.</p>
			<h4>INFORMATION SHARING</h4>
			<p>HappyCredit shares the sensitive personal information to any third party without obtaining the prior consent of the User in the following limited circumstances:</p>
			<p>a) When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations.</p>
			<p>b) HappyCredit proposes to share such information within its group companies and officers and employees of such group companies for the purpose of processing personal information on its behalf. We also ensure that these recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.</p>
			<h4>ACCESSING AND UPDATING PERSONAL INFORMATION</h4>
			<p>When you use HappyCredit's websites, we make good faith efforts to provide you, as and when requested by you, with access to your personal information and shall further ensure that any personal information or sensitive personal data or information found to be inaccurate or deficient shall be corrected or amended as feasible, subject to any requirement for such personal formation or sensitive personal data or information to be retained by law or for legitimate business purposes. We ask individual users to identify themselves and the information requested to be accessed, corrected or removed before processing such requests, and we may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort, jeopardize the privacy of others, or would be extremely impractical (for instance, requests concerning information residing on backup tapes), or for which access is not otherwise required. In any case where we provide information access and correction, we perform this service free of charge, except if doing so would require a disproportionate effort. Because of the way we maintain certain services, after you delete your information, residual copies may take a period of time before they are deleted from our active servers and may remain in our backup systems.</p>
			<h4>INFORMATION SECURITY</h4>
			<p>We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.</p>
			<p>All information gathered on HappyCredit is securely stored within the HappyCredit controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. And, of course, any information you include in a posting to the discussion areas is available to anyone with Internet access.</p>
			<p>However the internet is an ever evolving medium. We may change our privacy policy from time to time to incorporate necessary future changes. Of course, our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be.</p>
			<p>We use third-party advertising companies to serve ads when you visit our Web site. These companies may use information (not including your name, address, email address or telephone number) about your visits to this and other Web sites in order to provide advertisements about goods and services of interest to you. Any complaints or concerns with regards to content or to report any abuse of laws or breach of these terms may be taken up with Happy Credit as mentioned later in the page via post or by sending an email to info@happycredit.in.</p>
			<h4>Communication with Users</h4>
			<p>Your continued use of this site means that HappyCredit has your consent to send periodic communication regarding use of this site or Products offered through the Website or any of our subsidiary products and services. This communication can be through different media at HappyCredit’s disposal (e.g. e-mails, promotional SMS, Push Notifications etc.) HappyCredit reserves the right to communicate with users regarding the use of site or any product or service purchased on this site, or any of our subsidiary products or services.</p>
			<p>When you use the Website or send emails or other data, information or communication to us, you agree and understand that you are communicating with us through electronic records and you consent to receive communications via electronic records from us periodically and as and when required. We may communicate with you by email or by such other mode of communication, electronic or otherwise.</p>
			<h4>Grievance Redressal</h4>
			<p>Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature to</p>
			<p><b>
			HappyCredit,<br />
			(Cybergenes Innovations Pvt Ltd),<br />
			#677, 1st Floor, Suite 973, 13th Cross Road,<br />
			27th Main Road, Sector - 1, HSR Layout,<br />
			Bengaluru, Karnataka - 560102, India.<br />
			Phone: +91-95134 26304<br />
			Email: info@happycredit.in
			</b></p>
			<p>We request you to please provide the following information in your complaint:-</p>
			<ol>
				<li>A physical or electronic signature of a person authorized to act on behalf of the copyright owner for the purposes of the complaint.</li>
				<li>Identification of the copyrighted work claimed to have been infringed.</li>
				<li>Identification of the material on our website that is claimed to be infringing or to be the subject of infringing activity.</li>
				<li>The address, telephone number or e-mail address of the complaining party.</li>
				<li>A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law.</li>
				<li>A statement, under penalty of perjury, that the information in the notice of copyright infringement is accurate, and that the complaining party is authorized to act on behalf of the owner of the right that is allegedly infringed.</li>
			</ol>
			</div>
			</div>
		</Layout>
	)
}

export default TermsPage
